import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import shuffle from "shuffle-array";
import ReactImageFallback from "react-image-fallback";

export interface recipe {
    title: string,
    href: string,
    ingredients: string,
    thumbnail: string,
}

export const Slider = () => {
    const [hasResults, setHasResults] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [data, setData] = useState<recipe[] | undefined>();
    const [tries, setTries] = useState(0);

    useEffect(() => {
        async function parseData() {
            if (_.isEmpty(data) && tries < 10) {
                const storage: any = localStorage.getItem('recipes');
                let json: recipe[] = JSON.parse(storage);
                if (json) shuffle(json);
                setData(json);
                setHasResults(!_.isEmpty(json));
                // console.log(data, !_.isEmpty(data), tries);
            } else if (_.isEmpty(data) && tries >= 10 && tries <= 25) {
                await fetch("https://saltpepper.xyz/quote/random-recipes-api")
                    .then(res => res.json())
                    .then((result) => {
                        // console.log(result);
                        setData(result);
                        setHasResults(!_.isEmpty(result));
                        // console.log(data, !_.isEmpty(data), tries);
                    },
                        (error) => { }
                    )
            }
            setTries(tries + 1);
        }
        if (!hasResults) {
            parseData();
        }
    }, [data, hasResults, tries])

    const multiple = data && data.length > 1;
    const responsive = { desktop: { breakpoint: { max: 9999, min: 1600 }, items: 4 }, smolDesktop: { breakpoint: { max: 1600, min: 1200 }, items: 3 }, smollerDesktop: { breakpoint: { max: 1200, min: 1000 }, items: 2 }, superSmollerDesktop: { breakpoint: { max: 1000, min: 0 }, items: 1 } };

    if (!hasResults) return <></>

    return (
        <Carousel 
            infinite={multiple}
            arrows={false}
            autoPlay={!isHovering}
            autoPlaySpeed={2500}
            showDots={false}
            responsive={responsive}
            swipeable={multiple}
            draggable={multiple}
            beforeChange={() => setIsMoving(true)}
            afterChange={() => setIsMoving(false)}
        >
            {data && data.map((recipe, i) => {
                const rnd = Math.floor(Math.random() * 3) + 1;
                return (
                    <div 
                        key={recipe.title}
                        className={`flex overflow-hidden rounded-md border border-gray-300 bg-white mx-2 h-44`}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        <div className="flex items-center flex-shrink-0 w-40 pointer-events-none select-none">
                            <ReactImageFallback 
                                className="object-cover object-center w-full h-full" 
                                src={recipe.thumbnail} 
                                fallbackImage={`https://saltpepper.xyz/img/saltpepper.xyz/placeholder${rnd}.jpg`} 
                                initialImage={`https://saltpepper.xyz/img/saltpepper.xyz/placeholder${rnd}.jpg`} 
                                alt={recipe.title} 
                            />
                        </div>
                        <div className="flex flex-col self-stretch flex-grow px-4 py-2">
                            <div className="mb-1 font-serif text-xl pointer-events-none select-none truncate-1-lines">{recipe.title}</div>
                            <ul className="flex flex-row flex-wrap overflow-y-auto select-none max-h-24">
                                {recipe.ingredients.split(', ').slice(0, 4).map((ingredient, i) => {
                                    return <li className="px-2 mb-1 mr-3 text-sm border rounded" key={i}>{ingredient}</li>
                                })}
                                {
                                    recipe.ingredients.split(', ').length > 4 &&
                                    <li className="px-2 mb-1 mr-1 text-sm text-gray-400 border rounded" key={i + 1}>{recipe.ingredients.split(', ').length - 4} more...</li>
                                }
                            </ul>
                            <div className="mt-auto">
                                <a className="px-4 py-1 text-sm font-bold text-gray-600 uppercase transition-colors bg-gray-100 rounded hover:bg-gray-300 hover:text-gray-900 track-wider" href={recipe.href} target="_blank" rel="noreferrer">View The Recipe</a>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Carousel>
    )
};

export default Slider;