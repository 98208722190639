import React, { useState, useEffect, useRef } from "react";
import shuffle from "shuffle-array";
import $ from "jquery";
import Slider from "./components/Slider"

export const App = () => {
    const [color, setColor] = useState<any>('');
    const [query, setQuery] = useState("");
    const inputRef = useRef<any>(null);
    const divRef = useRef<any>(null);
    const formRef = useRef<any>(null);
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const urlParams = new URLSearchParams(url.search);
    const u = urlParams.get('uid');
    const e = urlParams.get('id');
    if(u) localStorage.setItem('uid',u);
    if(e) localStorage.setItem('extid',e);
    const userId = localStorage.getItem('uid');
    const extId = localStorage.getItem('extid');

    const handleSearchChange = async (query: string) => {
        setQuery(query);
        if (query.length > 0) {
            $.ajax({
                url: "https://api.bing.com/osjson.aspx",
                dataType: "jsonp",
                data: {
                    JsonType: "callback",
                    JsonCallback: "suggestCallback",
                    Market: "en-US",
                    query: query
                }
            });
        } else {
            localStorage.setItem('autocomplete', '');
        }
    }

    useEffect(() => {
        const colors = ['bg-red-50', 'bg-yellow-50', 'bg-green-50', 'bg-blue-50', 'bg-indigo-50', 'bg-purple-50', 'bg-pink-50'];
        if (color.length === 0) {
            shuffle(colors);
            setColor(colors.pop());
        }
    }, [color])

    return (
        <div>
            <div ref={divRef} className={`w-screen h-screen flex flex-col items-center justify-center ${color}`} onClick={() => { document.querySelector('input')?.focus(); localStorage.setItem('autocomplete', ''); }}>
                <form ref={formRef} className="w-full z-10 -mt-56 px-5" action="https://cheftab.xyz/s" method="GET">
                    <div className="relative flex flex-row transition-all bg-white w-full max-w-5xl mx-auto rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-blue-500 focus-within:ring-blue-700">
                        <input className="w-full h-16 bg-transparent px-5 text-2xl focus:outline-none z-10" ref={inputRef} value={query} placeholder="type here to search..." type="text" name="q" onChange={e => handleSearchChange(e.target.value)} required={true} autoFocus autoComplete="off" />
                        {query.length > 0 && localStorage.getItem('autocomplete') &&
                            <div className="absolute top-full right-0 left-0 flex flex-col transition-all rounded-b-lg bg-white ring-1 ring-blue-500 overflow-hidden z-0 -mt-1">
                                {localStorage.getItem('autocomplete')?.split(',').splice(0, 5).map((e: any, i: any) => {
                                    return <div className="cursor-pointer py-2 px-5 hover:bg-gray-100" onClick={() => { inputRef.current.value = e; formRef.current.submit(); }} key={i}>{e}</div>
                                })}
                            </div>
                        }
                        <input value={userId ? userId : ''} type="hidden" name="uid" readOnly={true} />
                        <input value={extId ? extId : ''} type="hidden" name="id" readOnly={true} />
                        <button className="transition-all rounded-sm hover:bg-gray-100 active:outline-none focus:outline-none p-2" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
            <div className="absolute bottom-5 left-5 right-5">
                <Slider/>
            </div>
        </div>
    )
};
